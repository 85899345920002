@use "../config/" as *;
.waf-video-news {
  overflow-x: hidden;
  &.waf-component {
    @extend %p-zero;
    @extend %m-t-zero;
  }
  .layout-wrapper {
    @extend %m-l-full;
    @extend %relative;
  }
  // .head-tab,
  .social-share-wrap,
  .filter-section,
  .swiper-pagination,
  .swiper-button-prev,
  .swiper-button-next,
  .pagination-wrap {
    @extend %d-none;
  }
  .waf-head {
    @extend %m-b-two-space;
  }
  .title {
    @extend %capitalize;
  }
  .loadmore-wrap {
    height: 3.2rem;
    @include position(0, var(--full-space));
    @extend %flex-n-c;
  }
  .loadmore {
    border: unset;
    background-color: unset;
    @extend %c-orange-10;
    @extend %font-14-pb;
  }
  .article {
    &-list {
      gap: var(--full-space);
      @extend %p-b-half;
    }
    &-title {
      margin-block: var(--full-space) var(--half-space);
      color: var(--c-blue);
      font-weight: 400;
      @include truncate-vertical(2, 20, 24, $font-pr);
    }
    &-meta {
      .meta {
        &-date {
          @extend %c-black-6;
          &::before {
            @extend %c-black-bg-6;
          }
        }
      }
    }
    &-thumbnail {
      .item-type-icon {
        display: none;
      }
    }
  }
  .article-commentbox,
  .article-description,
  .icon-time-wrapper,
  .meta-author,
  .reaction-section {
    @extend %d-none;
  }
  &.waf-listing {
    .waf-head {
      padding-right: var(--full-space);
    }
    .head-tab {
      @extend %flex;
    }
    .article-meta {
      flex-direction: row;
      .meta {
        position: relative;
        padding-right: var(--full-space);
        margin-left: 0;
        padding-left: 0;
        &:not(:last-child) {
          &::after {
            position: absolute;
            right: 0;
            content: '|'; 
            margin-right: .4rem;
            @extend %c-black-6;
           }
        }
      }
      .meta-date {
        &:before {
          content: unset;
        }
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-video-news {
    &.waf-listing {
      margin-inline: var(--half-space-negative);
      .swiper::after {
        height: 100%;
        right: calc(-1 * var(--container-white-space));
        background: linear-gradient(269deg, hsl(var(--hsl-c-white) / .84) 50.02%, hsl(var(--hsl-c-white) / 0) 100%);
      }
    }
    .layout-wrapper {
      padding-bottom: 8rem;
      margin-left: auto;
    }
    .preview-swiper {
      overflow: visible;
    }
    .article {
      &-list {
        gap: unset;
      }
      &-thumbnail {
        .item-type-icon {
          display: flex;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
      &::after {
        color: var(--c-blue);
      }
    }
    .loadmore {
      font-size: 1.6rem;
    }
  }
}
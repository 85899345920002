@use "../config/" as *;
@each $event,
$event-key in $events {
    @include event-title($event, $event-key);
}
.waf-scorestrip {
    @extend %m-y-two-space;
    @extend %p-half;
    @extend %head-position;
    &.widget-layout-11 {
        margin-block: 4rem 6.4rem;
        .waf-head .head-tab {
            bottom: calc(var(--two-space) * -1);
        }
    }
    .dropdown-group {
        @extend %d-none;
    }
    .sub-title {
        align-items: center;
        @extend %font-20;
        @extend %c-blue-10;
    }
    .meta {
        height: 2rem;
        position: relative;
        display: inline-flex;
        margin-inline: calc(var(--half-space)/2);
        @extend %c-blue-4;
        @extend %font-12-pr;
        &.number {
            @extend %font-12-pr;
        }
        &.style,
        &.age {
            @extend %uppercase;
        }
        &:not(:last-child) {
            &::after {
                content: '';
                width: .1rem;
                height: 60%;
                @include position-combo(y-center, -0.7rem);
                @extend %d-block;
                @extend %c-blue-bg-3;
            }
        }
        &:empty {
            &::after {
                content: unset;
            }
        }
    }
    .number {
        @extend %font-14-pb;
    }
    .fname {
        @extend %d-block;
        @extend %font-12-pr;
        @extend %c-black-6;
    }
    .lname {
        height: 2.4rem;
        display: inline-flex;
        overflow: hidden;
        @extend %c-black-10;
        @extend %font-14-pr;
    }
    .card {
        &-list {
            overflow: auto;
            @include card-count(1.2, var(--half-space), nowrap);
        }
        &-item {
            backdrop-filter: blur(0.4rem);
            padding: var(--three-fourth-space);
            border: 0;
            @extend %card;
            @extend %c-white-bg-8;
            @extend %m-b-full;
        }
        &-header {
            @extend %flex;
        }
        &-thumbnail .image {
            background-color: var(--c-white-2);
        }
        &-content {
            @extend %flex-n-c;
            @extend %w-100;
            &.won {
                .number {
                    @extend %c-orange-10;
                }
                .image {
                    border: 0.1rem solid var(--c-orange);
                }
            }
        }
        &-score {
            min-width: 5rem;
            text-align: end;
        }
        &-label {
            width: calc(100% - 10rem);
            @extend %m-l-full;
        }
        &-body {
            @extend %m-y-half;
        }
        &-footer {
            @extend %text-center;
            .text {
                @extend %c-orange-10;
                @extend %font-14-pb;
            }
        }
        &-status {
            @extend %d-none;
            @extend %badges;
        }
    }
    .team {
        &-a {
            @include border(0.5, c-pure-black, 1, bottom);
            @extend %m-b-half;
            @extend %p-b-half;
        }
    }
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
            &:after {
                font-size: 4.2rem;
            }
        }
        &-scrollbar {
            @extend %d-none;
        }
    }
    &.waf-livestrip {
        @extend %m-b-zero;
        .meta {
            height: 1.8rem;
            &:nth-last-child(2) {
                margin-right: 0;
                &::after {
                    content: unset;
                }
            }
            &:last-of-type {
                @extend %d-none;
            }
        }
        .card {
            &-header {
                @extend %flex-sb-c;
            }
            &-list {
                @include card-count(1.2, var(--half-space), nowrap);
                @extend %flex-fs-c;
            }
            &-item {
                padding: 1.2rem;
                @extend %m-b-zero;
            }
            &-body {
                margin-block: 1.2rem;
            }
            &-status {
                padding: 0.6rem;
                gap: var(--quarter-space);
                @extend %flex-n-c;
                @extend %c-error-bg-10;
                &::before {
                    content: '';
                    width: 0.9rem;
                    height: 0.9rem;
                    aspect-ratio: 1/1;
                    flex-shrink: 0;
                    background-image: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}');
                }
                .text {
                    line-height: var(--half-space);
                    @extend %capitalize;
                    @extend %font-12;
                }
            }
        }
        .number {
            font-family: $font-psb;
        }
        .won {
            .number {
                font-family: $font-pb;
                @extend %c-white-10;
            }
            .image {
                border: none;
            }
        }
    }
}
.watch-center {
    .waf-livestrip.waf-scorestrip {
        margin-top: 0;
    }
}
.watch-center {
    .waf-livestrip.waf-scorestrip {
        bottom: 22rem;
        left: 0;
        transform: unset;
        top: unset;
        height: max-content;
        z-index: 1;
        .swiper {
            height: 100%;
        }
        .card {
            &-list {
                flex-direction: row;
                align-items: baseline;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-scorestrip {
        margin-block: calc(4 * var(--full-space)) calc(2 * var(--full-space));
        &.widget-layout-11 {
            margin-block: calc(4 * var(--full-space)) calc(2 * var(--full-space));
        }
        .layout-wrapper {
            max-width: 100%;
            // margin-left: calc(var(--container-white-space) - var(--full-space));
            // margin-right: 0;
        }
        .waf-head {
            margin-right: calc(var(--container-white-space)/1.5);
            .head-tab {
                position: unset;
                @include translate(0, 0);
            }
        }
        .tabs {
            width: max-content;
        }
        .card {
            &-list {
                overflow: unset;
                @include card-count(3.5, 0rem, nowrap)
            }
            &-item {
                margin-bottom: calc(var(--full-space) + 4rem);
                @include transition(0.4s);
                &:hover {
                    margin-bottom: var(--full-space);
                    box-shadow: 0 0.4rem 1.6rem 0 hsl(var(--hsl-c-orange)/0.6);
                    .card-footer {
                        opacity: 1;
                        margin-bottom: 0;
                        @include transition(0.4s);
                    }
                }
            }
            &-footer {
                height: 4rem;
                width: calc(100% + var(--two-space));
                margin-bottom: -4rem;
                @include position(null, null, var(--full-space-negative), var(--full-space-negative), relative);
                @include bg(c-orange);
                @include transition(0.4s);
                .text {
                    @include color(c-white, 10);
                }
            }
            &-action {
                height: 100%;
                @include flex-config(flex, null, center, center);
            }
        }
        .swiper {
            position: relative;
            padding-top: var(--full-space);
            &::after {
                content: '';
                width: 7rem;
                height: 90%;
                z-index: var(--zindex1);
                @include linear-gradient(268.67deg, hsl(var(--hsl-c-white)/0.5) 56.77%, hsl(var(--hsl-c-blue)/0) 98.82%);
                @include position-combo(tr);
            }
            &-button-prev,
            &-button-next {
                display: block;
                top: 40%;
            }
        }
        .head-title {
            @include flex-config(flex, row, null, center);
            .title {
                position: relative;
                padding-right: var(--one-n-half-space);
                &::after {
                    content: '|';
                    @include color(c-blue, 4);
                    @include position(null, 0.6rem, 0.2rem);
                }
            }
        }
        &.waf-livestrip {
            .swiper {
                padding-top: 0;
            }
            .layout-wrapper {
                margin-left: 0;
                height: 100%;
            }
            .card {
                &-list {
                    overflow-x: auto;
                    @include card-count(2.2, var(--half-space), nowrap);
                }
                &-item {
                    // height: 17rem;
                    &:hover {
                        box-shadow: none;
                        margin: 0;
                    }
                }
            }
        }
    }
    .watch-center {
        .waf-livestrip.waf-scorestrip {
            bottom: var(--full-space);
            left: calc(2 * var(--two-space));
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-scorestrip {
        &.waf-livestrip {
            height: 100%;
            z-index: var(--overlay-image);
            margin-top: 0;
            padding-block: var(--full-space);
            // @include position-combo(y-center, var(--container-white-space));
            transform: translateY(-50%); //hot fixes
            @include position(calc(50% + 6.5rem), var(--container-white-space)); //hot fixes
            .meta {
                @include color(c-pure-white, 4);
                &::after {
                    @include bg(c-pure-white, 4);
                }
            }
            .fname {
                @include color(c-pure-white, 4);
            }
            .lname {
                @include color(c-pure-white, 10);
            }
            .number {
                @include color(c-pure-white, 10);
            }
            .card {
                &-list {
                    overflow: unset;
                    @include card-count(2.2, var(--three-fourth-space), nowrap);
                    @include flex-config(flex, column);
                }
                &-item {
                    width: 30rem;
                    max-width: 30rem;
                    max-height: 19rem;
                }
                &-body {
                    margin-bottom: 0;
                }
            }
            .team {
                &-a {
                    @include border(0.5, c-pure-white, 1, bottom);
                }
            }
            .swiper {
                height: calc(var(--window-inner-height) - var(--header-height) - 6rem);
                &::after {
                    content: unset;
                }
                &-button-prev,
                &-button-next,
                &-pagination {
                    display: none;
                }
            }
            .waf-body {
                height: 100%;
            }
        }
    }
}
@media (min-width:$large-desktop-min-width) {
    .waf-scorestrip {
        .layout-wrapper {
            margin-left: calc(var(--container-white-space) - var(--full-space));
            margin-right: 0;
        }
    }
}
@media (min-width:$xl-desktop-min-width) {
    .waf-scorestrip {
        &.waf-livestrip {
            top: 10rem;
            @include translate(0, 0);
        }
    }
}
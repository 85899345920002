@use '../config/' as *;
.waf-uww-plus {
  padding: 0;
  margin: 0 var(--half-space-negative);
  .layout-wrapper {
    max-width: unset;
    margin: 0;
  }
}
.waf-banner,
.waf-subscribe,
.waf-faq {
  padding: 0;
  margin: 0;
}
.waf-subscribe,
.waf-faq {
  .note {
    @extend %c-white-6;
    @extend %font-12;
    @extend %text-center;
  }
}
.waf-banner {
  min-height: 65rem;
  isolation: isolate;
  @extend %relative;
  &::after {
    content: "";
    z-index: var(--zindex-pattern);
    background: url(/static-assets/images/cssimages/uww-plus-banner-bg.png?v=#{$image-version}) center / cover no-repeat;
    pointer-events: none;
    @include position-combo("bl");
    @extend %w-100;
    @extend %h-100;
  }
  .layout-wrapper {
    @extend %h-100;
  }
  .content-wrapper {
    padding: 0 var(--full-space) 4rem;
    // background: linear-gradient(180deg, hsl(var(--hsl-c-blue) / 0) 0%, hsl(var(--hsl-c-blue) / 0.95) 100%);
    background: linear-gradient(359.95deg, var(--c-blue) 64.46%, rgba(4, 0, 63, 0) 78.56%);
    @include position-combo("bl");
    @extend %w-100;
  }
  .title {
    line-height: 1.22;
    @extend %m-b-one-n-half;
    @extend %c-pure-white-10;
    @extend %font-36-sb;
    @extend %uppercase;
  }
  .content {
    @extend %m-b-full;
  }
  .desc {
    @extend %c-pure-white-10;
    @extend %font-16-pr;
  }
  .btn-code {
    @extend %p-l-half;
    .btn-text {
      @extend %c-orange-10;
      @extend %font-16-pr;
    }
  }
  .btn-site {
    height: 4rem;
    @extend %w-100;
    @extend %rounded-radius;
    @extend %flex-c-c;
    @extend %c-white-bg-10;
    .btn-text {
      @extend %c-blue-10;
      @extend %font-16-pr;
    }
  }
}
.waf-subscribe {
  width: unset;
  padding: 4rem var(--full-space);
  @extend %c-blue-bg-10;
  &.uww-plus {
    @extend %d-block;
    .layout-wrapper {
      max-width: var(--container-max-width);
      margin-inline: auto;
    }
  }
  .form-body-wrapper {
    margin-block: 0;
  }
  .btn-sub {
    width: fit-content;
  }
  .form-action {
    @extend %flex-c-c;
  }
  .text-passes {
    @extend %text-center;
    @extend %m-t-one-n-half;
    @extend %c-white-6;
    @extend %font-12-pr;
  }
  .note {
    @extend %m-t-one-n-half;
  }
}
.waf-video .waf-video-element {
  height: auto;
  object-fit: cover;
  @extend %w-100;
  @extend %d-block;
}
.waf-video-news {
  background-color: var(--c-blue);
  &.waf-component {
    padding-block: 4rem;
  }
  .title,
  .article-title {
    color: var(--c-white);
  }
  .article-meta .meta-date {
    color: hsl(var(--hsl-c-white) / .8);
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var(--c-light-grey);
  }
}
.waf-faq {
  padding-block: var(--three-space);
  @extend %c-blue-bg-10;
  .layout-wrapper {
    margin-inline: auto;
    @extend %p-x-full;
  }
  .info-strip-section {
    margin-bottom: 9.6rem;
  }
  .waf-info-strip {
    &-list {
      flex-wrap: wrap;
      gap: var(--one-n-half-space);
      @extend %p-x-half;
      @extend %flex-c-n;
    }
    &-item {
      max-width: 19rem;
      width: calc(50% - 1.2rem);
      aspect-ratio: 1;
      gap: var(--full-space);
      @extend %circle-radius;
      @extend %flex-column-c-c;
      @extend %c-white-bg-1;
      .image {
        width: 100%;
        height: min(35%, 5.5rem);
      }
      .text {
        max-width: 65%;
        @extend %c-white-8;
        @extend %text-center;
      }
    }
  }
  .accordion {
    &-title {
      line-height: 1.2;
      @extend %p-x-full;
      @extend %m-t-zero;
      @extend %c-pure-white-10;
      @extend %font-24-sb;
      @extend %uppercase;
    }
    &-item {
      border-bottom: .1rem solid hsl(var(--hsl-c-white) / 0.5);
      @extend %p-y-full;
      @extend %m-b-zero;
      &::after {
        content: unset;
      }
    }
    &-list {
      list-style: disc;
      padding-left: var(--one-n-half-space);
      .text {
        font-size: 1.2rem;
        &:not(:last-child) {
          @extend %m-b-full;
        }
      }
    }
    &-body {
      margin-top: 1rem;
    }
  }
  .note {
    max-width: 44.4rem;
    margin: 5rem auto 0;
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .waf-faq .waf-info-strip-item {
    .image {
      height: 4rem;
    }
    .text {
      max-width: 80%;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-banner {
    max-width: 100%;
    aspect-ratio: 21/9;
    min-height: 55rem;
    contain: paint;
    .layout-wrapper {
      @include flex-config(flex, column, center);
    }
    .thumbnail {
      width: 62%;
      aspect-ratio: 1.31;
      @include position(null, 0, -5%);
      img {
        object-position: bottom center;
      }
    }
    .content-wrapper {
      width: auto;
      padding: 5rem 0;
      margin-inline: var(--container-white-space);
      position: unset;
      background: none;
    }
    .title {
      margin-bottom: var(--full-space);
      @include truncate-vertical(3, 48, 58);
    }
    .content {
      margin-bottom: var(--three-space);
    }
    .desc,
    .btn-code .btn-text {
      font-size: 1.8rem;
    }
    .btn-site {
      width: auto;
      padding-inline: var(--full-space);
    }
  }
  .waf-subscribe {
    padding-block: 8rem;
    .form-action {
      align-items: center;
    }
    .text-passes {
      max-width: 100rem;
      margin-inline: auto;
    }
  }
  .waf-video .layout-wrapper {
    max-width: unset;
    margin: 0;
  }
  .waf-faq {
    padding-block: 12rem;
    position: relative;
    background: transparent;
    &::before {
      content: "";
      z-index: var(--zindex-pattern);
      background: var(--c-blue) url(/static-assets/images/cssimages/faq-bg-d.png?v=#{$image-version}) center / cover no-repeat;
      pointer-events: none;
      @include position-combo(inset);
    }
    .layout-wrapper {
      width: calc(var(--container-max-width) * .828);
      padding-inline: 0;
    }
    .waf-info-strip {
      &-list {
        padding-inline: 0;
        gap: 4rem;
        flex-wrap: wrap;
      }
      &-item {
        max-width: unset;
        width: calc(20% - 3.2rem);
        min-width: 15rem;
        .text {
          max-width: 60%;
        }
      }
    }
    .accordion-title {
      font-size: 3.2rem;
      text-align: center;
    }
  }
  .uww-plus {
    .u-twenty-three-wroldcup {
      .card-item {
        min-height: 17rem;
        .card-wrapper {
          height: 100%;
        }
      }
    }
  }
  .waf-video-news {
    .layout-wrapper {
      padding-bottom: 0;
    }
    &.waf-component {
      padding-block: 8rem;
      .swiper::after {
        background: linear-gradient(268.67deg, hsl(var(--hsl-c-cetacean-blue) / .6) 56.77%, hsl(var(--hsl-c-cetacean-blue) / 0) 98.82%);
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-banner .title {
    @include truncate-vertical(3, 64, 84);
  }
}
@media screen and (min-width: $large-desktop-min-width) {
  .waf-banner .content-wrapper {
    width: 51.04%;
  }
}
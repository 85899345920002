@use "../config/" as *;
.waf-showcase {
    margin: 0 var(--half-space-negative);
    @extend %p-zero;
    .article {
        &-thumbnail {
            &::after {
                content: '';
                @include position-combo(inset);
                @include linear-gradient(89deg, hsl(var(--hsl-c-blue)/23%) 0%, hsl(var(--hsl-c-white)/52%) 100%);
            }
        }
        &-content {
            @extend %p-half;
            @include position-combo(bl);
        }
        &-title {
            @extend %c-white-10;
            @include truncate-vertical(2, 20);
        }
        &-error {
            z-index: var(--zindex1);
            @extend %flex-c-c;
            @extend %p-full;
            @extend %h-100;
            @extend %w-100;
            @extend %position-t-l;
            @extend %c-blue-bg-10;
            .article-title {
                height: auto;
                @extend %text-center;
                @extend %m-y-zero;
                @extend %flex-c-n;
            }
        }
    }
    .video-wrap {
        position: relative;
        z-index: var(--zindex-content);
        @extend %h-100;
        & > * {
            @extend %w-100;
            @extend %h-100;
        }
    }
    .item-type-icon {
        width: 5rem;
        cursor: pointer;
        z-index: var(--zindex1);
        @extend %flex-c-c;
        @extend %ratio-1-1;
        @include position-combo(center);
        &:after {
            @extend %c-white-10;
            @include icon('\e814');
            @include font(30);
        }
    }
    .swiper-button {
        @extend %d-none;
    }
    &.bottom-player {
        .preview-swiper {
            height: auto;
        }
    }
}
.watch-center {
    .waf-showcase {
        .article-thumbnail {
            .img-box {
                border-radius: 0;
                cursor: pointer;
            }
            &::after {
                pointer-events: none;
            }
        }
        .article-content {
            pointer-events: none;
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-showcase {
        .layout-wrapper {
            max-width: 100%;
        }
        .preview-swiper {
            height: auto;
            // height: calc(var(--window-inner-height) - var(--header-height));
        }
        .item-type-icon {
            width: 9rem;
            &::after {
                font-size: 6rem;
            }
        }
        .article {
            &-content {
                width: calc(50% - 15rem);
                top: 30%;
                left: calc(3 * var(--two-space));
                z-index: var(--zindex-content);
            }
            &-title {
                z-index: var(--zindex-content);
                @include truncate-vertical(2, 24);
            }
            &-error {
                .article-title {
                    width: 70%;
                }
            }
        }
    }
}